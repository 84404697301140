import { CupFill, StarFill } from "react-bootstrap-icons";
export default function Mesa(props){
    const { pos, mesaDetails } = props;
    return(
        <div className="md:flex">
                  <div className="coffe-cup mt-12 md:mt-[95px] mx-auto md:scale-150">
                    <span className="absolute text-white w-full text-center h-full align-baseline pt-3 text-3xl font-mono z-10">
                      {pos}
                    </span>
                    <div className="smoke"></div>
                    <div className="smoke"></div>
                    <div className="smoke"></div>
                    <div className="relative">
                      <CupFill
                        size={96}
                        color="#f05e9e"
                        className="absolute mt-[-18px]"
                      />
                    </div>
                  </div>
                  <div className="text-sm text-gray-500 border-2 mt-3 border-pink-300 rounded-md md:rounded-xl px-3 py-1 md:p-0 mb-2 shadow-md md:bg-pink-300 md:w-2/3">
                    <div className="md:p-3 md:rounded-l-xl md:rounded-r-md md:bg-white md:m-1">
                      <h3 className="text-pink-500 rounded-md p-2 text-sm mt-2">
                        Personal más frecuente
                        <span className="bg-white rounded-md px-2 float-right text-gray-500 ">
                          {mesaDetails[pos]?.mostFrequentMozo}
                        </span>
                      </h3>
                      <h3 className="text-pink-500 rounded-md p-2 text-sm mt-2">
                        Propina más alta:
                        <span className="bg-white rounded-md px-2 float-right text-gray-500  font-normal">
                          ${mesaDetails[pos]?.highestMonto}
                        </span>
                      </h3>
                      <h3 className="text-pink-500 rounded-md p-2 text-sm mt-2">
                        Propina promedio:
                        <span className="bg-white rounded-md px-2 float-right text-gray-500 font-normal">
                          $
                          {mesaDetails[pos]?.averageMonto !== undefined
                            ? `${mesaDetails[pos]?.averageMonto.toFixed(2)}`
                            : "No hay datos"}
                        </span>
                      </h3>
                      <h3 className="text-pink-500 rounded-md p-2 text-sm mt-2">
                        Calificación promedio:
                        <span className="bg-white rounded-md px-2 float-right text-gray-500 font-normal">
                          {mesaDetails[pos]?.averageCalificacion.toFixed()}
                          <StarFill className="float-right ml-1 mt-0.5" />
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
    )
}