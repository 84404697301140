import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import TurnoInfo from "./TurnoInfo";
import Transferencias from "./Transferencias";
import "react-calendar/dist/Calendar.css";
import ToolTip from "../utils/ToolTip.js";
import { useRestaurant } from "../hooks/useRestaurant";

const ITEMS_PER_PAGE = 1;

const TurnosCalendar = () => {
  const [turnos, setTurnos] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const { roles, getRolesData, isLoading } = useRestaurant({ token: sessionStorage.getItem("token") });

  const tileContent = ({ date, view }) => {
    if (view === "month" && getTurnosForDate(date).length > 0) {
      return (
        <div className="w-1.5 h-1.5 absolute ml-0.5 mt-5 rounded-full dotActive" />
      );
    }
    return null;
  };

  useEffect(() => {
    getRolesData()
  }, [getRolesData])


  useEffect(() => {
    const fetchTurnos = async () => {
      setLoading(true);
      const token = sessionStorage.getItem("token");
      if (!token) {
        throw new Error("No token found in session storage");
      }

      try {
        const response = await fetch(
          "https://tippitqr.com/userdb/turnos_activos",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Error fetching turnos");
        }

        const data = await response.json();
        
        setTurnos(data.filter((turno) => !turno.activo));
      } catch (error) {
        if (process.env.NODE_ENV === "development") {
          console.log(error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchTurnos();
  }, []);

  const getTurnosForDate = (date) => {
    return turnos.filter((turno) => {
      const turnoDate = new Date(turno.hora_inicio);
      return turnoDate.toDateString() === date.toDateString();
    });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const currentTurnos = getTurnosForDate(selectedDate).slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const totalPages = Math.ceil(getTurnosForDate(selectedDate).length / ITEMS_PER_PAGE);

  return (
    <div className="w-full p-4">
      <hr className="mb-5 mt-7 mx-[-30px]" />
      <h1 className="text-2xl font-semibold mb-2">Turnos Pasados</h1>
      <p className="text-md md:text-lg mb-7">
        Para ver el historial de turnos pasados, seleccione una fecha en el
        calendario. Los días con turnos registrados estarán resaltados. Al hacer
        clic en una fecha destacada, podrá ver los detalles de los turnos
        correspondientes a esa fecha.
      </p>
      {loading ? (
        <p>Cargando Turnos...</p>
      ) : (
        <div className="flex flex-col">
          <div className="flex flex-col lg:flex-row justify-between">
            <div className="lg:w-2/3">
              <div className="p-6 bg-white rounded-3xl shadow-md border border-gray-200 max-w-sm">
                <h3 className="text-lg font-semibold mb-4 flex items-center gap-2">
                  <ToolTip position="bottom" width="w-80" text="Esta herramienta te permite ver los turnos pasados para calcular la propina a repartir entre los mozos en partes iguales y proporcionales." />
                  Historial de turnos pasados:
                </h3>
                <Calendar
                  onChange={handleDateChange}
                  value={selectedDate}
                  className="custom-calendar"
                  tileContent={tileContent}
                  tileClassName={({ date, view }) => {
                    if (view === "month") {
                      if (date.toDateString() === selectedDate.toDateString()) {
                        return "bg-blue-500 text-white rounded-lg";
                      }
                      if (getTurnosForDate(date).length > 0) {
                        return "bg-blue-200";
                      }
                    }
                    const today = new Date();
                    if (
                      date.getDate() === today.getDate() &&
                      date.getMonth() === today.getMonth() &&
                      date.getFullYear() === today.getFullYear()
                    ) {
                      return 'bg-pink-500 text-white rounded-full';
                    }
                    return 'hover:bg-pink-200';
                  }}
                  navigationLabel={({ date }) =>
                    `${date.toLocaleDateString('es-ES', { month: 'long', year: 'numeric' })}`
                  }
                  nextLabel=">"
                  prevLabel="<"
                />
              </div>
            </div>
            <TurnoInfo turno={currentTurnos} />
          </div>
          <div className="w-full mr-7 rounded-2xl order-2 lg:order-1 mt-4 lg:mt-6 border-4 border-gray-200 bg-white">
            {currentTurnos ? (
              currentTurnos.map((turno) => (
                <div key={turno._id} className="mx-2 mt-3 mb-7 rounded-xl">
                  <h1 className="text-2xl font-bold mt-2 flex items-center">
                    Informe del turno
                  </h1>
                  <div className="rounded-t-md w-full p-5 flex flex-row items-center">
                    <p className="text-xl mb-1 mr-2">
                      <strong>Fecha Inicio:</strong>{" "}
                      {new Date(turno.hora_inicio).getDate()} de {new Date(turno.hora_inicio).toLocaleString('es-ES', { month: 'long' })}
                    </p>
                    <p className="text-xl mb-1 mr-2">
                      <strong>Apertura:</strong> {new Date(turno.hora_inicio).getHours()}:{new Date(turno.hora_inicio).getMinutes()}
                    </p>
                    <p className="text-xl mb-1 mr-2">
                      <strong>Cierre:</strong> {new Date(turno.hora_fin).getHours()}:{new Date(turno.hora_fin).getMinutes()}
                    </p>
                  </div>

                  {turno.transferencias && turno.transferencias.length > 0 && (
                    <Transferencias transferencias={turno.transferencias} />
                  )}
                </div>
              ))
            ) : (
              <div className="flex items-center justify-center h-full">
                <p className="text-gray-500 text-center text-2xl mr-2">
                  Por favor, elegí una fecha
                </p>{" "}
              </div>
            )}

            {!isLoading && roles && currentTurnos.length > 0 && (
              <div className={`flex ${roles.length <= 2 ? "justify-center" : "justify-between"} gap-4`}>
                {roles.map((role, index) => (
                  <div key={index} className="w-full sm:w-40 p-4 bg-white rounded-lg text-center border flex flex-col justify-between mb-4">
                    <p className="text-sm font-bold">{role.nombre_rol}</p>
                    <p className="text-xl font-bold">${(role.porcentaje / 100 * (currentTurnos[0]?.monto_efectivo+ currentTurnos[0]?.pagos?.reduce((total, pago) => total + pago.monto, 0))).toFixed(2)}</p> 
                    <p className="text-gray-500 text-sm">Total generado</p>
                  </div>
                ))}
              </div>
            )}

            {totalPages > 1 && (
              <div className="flex justify-center mt-4 mb-4">
                {Array.from({ length: totalPages }, (_, i) => (
                  <button
                    key={i}
                    onClick={() => handlePageChange(i + 1)}
                    className={`px-3 py-1 mx-1 rounded-full ${currentPage === i + 1
                      ? "bg-pink-500 text-white"
                      : "bg-gray-200 text-gray-700"
                      }`}
                  >
                    {i + 1}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TurnosCalendar;
