// IndexedDB.js

export const createIndexedDB = () => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('MiPersonal', 1);
        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains('personas')) {
                db.createObjectStore('personas', { keyPath: 'id' });
                console.log('Object store "personas" created.');
            }
        };
        request.onsuccess = () => {
            console.log('IndexedDB abierta con exito.');
            resolve();
        };
        request.onerror = (event) => {
            console.error('Error al abrir IndexedDB:', event.target.error.message);
            reject('Error al abrir IndexedDB');
        };
    });
};

export const saveToIndexedDB = (data) => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('MiPersonal', 1);
        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains('personas')) {
                db.createObjectStore('personas', { keyPath: 'id' });
            }
        };
        request.onsuccess = (event) => {
            const db = event.target.result;
            const transaction = db.transaction('personas', 'readwrite');
            const store = transaction.objectStore('personas');
            data.forEach(item => {
                if (item.id) store.put(item);
                else console.error('Item sin ID:', item);
            });
            transaction.oncomplete = () => {
                console.log('La data fue guardada con exito a IndexedDB');
                resolve();
            };
            transaction.onerror = (event) => {
                console.error('Error al guardar la data en IndexedDB:', event.target.error.message);
                reject('Error guardando la data en IndexedDB');
            };
        };
        request.onerror = (event) => {
            console.error('Error abriendo IndexedDB:', event.target.error.message);
            reject('Error abriendo IndexedDB');
        };
    });
};

export const doesIndexedDBExist = () => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('MiPersonal');
        let dbExists = true;
        request.onupgradeneeded = (event) => {
            dbExists = false;
            event.target.transaction.abort();
        };
        request.onsuccess = (event) => {
            event.target.result.close();
            resolve(dbExists);
        };
        request.onerror = () => resolve(false);
    });
};


export const getFromIndexedDB = async () => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('MiPersonal', 1);

        request.onsuccess = (event) => {
            const db = event.target.result;
            const transaction = db.transaction('personas', 'readonly');
            const store = transaction.objectStore('personas');
            const getAllRequest = store.getAll();

            getAllRequest.onsuccess = () => {
                resolve(getAllRequest.result);
            };

            getAllRequest.onerror = (event) => {
                reject(event.target.error);
            };
        };

        request.onerror = (event) => {
            reject(event.target.error);
        };
    });
};