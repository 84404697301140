import React, { useState, useEffect } from "react";

const Switch = ({ onChange, checked }) => {
  const [isOn, setIsOn] = useState(checked || false);

  useEffect(() => {
    setIsOn(checked); 
  }, [checked]);

  const toggleSwitch = () => {
    const newValue = !isOn;
    setIsOn(newValue);
    onChange(newValue);
  };

  return (
    <div className="flex items-center">
      <button
        type="button"
        onClick={toggleSwitch}
        className={`${
          isOn ? "bg-pink-500" : "bg-gray-300"
        } relative inline-flex items-center mx-auto h-6 rounded-full w-11 transition-colors duration-200 ease-in-out focus:outline-none focus:shadow-outline`}
      >
        <span
          className={`${
            isOn ? "translate-x-6" : "translate-x-1"
          } inline-block w-4 h-4 transform bg-white rounded-full transition-transform duration-200 ease-in-out`}
        />
      </button>
    </div>
  );
};

export default Switch;