import { useState, useRef, useEffect } from "react"


function Photo({ photoRef }) {

    const [photo, setPhoto] = useState('');

    const isIphone = () => /iPhone/.test(navigator.userAgent);

    const [isIphoneDevice, setIsIphoneDevice] = useState(false);
    useEffect(() => {
        setIsIphoneDevice(isIphone());
    }, []);
    const [cameraStatus, setCameraStatus] = useState('idle');
    const videoRef = useRef(null);
    const canvasRef = useRef(null);

    //Subir Foto
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;

            img.onload = () => {
                // Crear un canvas para el redimensionamiento
                const resizeCanvas = document.createElement('canvas');
                const resizeCtx = resizeCanvas.getContext('2d');

                const targetWidth = 500;
                const scaleFactor = targetWidth / img.width;
                const targetHeight = img.height * scaleFactor;

                resizeCanvas.width = targetWidth;
                resizeCanvas.height = targetHeight;

                resizeCtx.drawImage(img, 0, 0, targetWidth, targetHeight);

                const cropCanvas = document.createElement('canvas');
                const cropCtx = cropCanvas.getContext('2d');

                const cropSize = 400;
                cropCanvas.width = cropSize;
                cropCanvas.height = cropSize;

                const sx = (targetWidth - cropSize) / 2;
                const sy = (targetHeight - cropSize) / 4;

                cropCtx.drawImage(resizeCanvas, sx, sy, cropSize, cropSize, 0, 0, cropSize, cropSize);

                const croppedImage = cropCanvas.toDataURL();
                setPhoto(croppedImage);
                photoRef.current.src = croppedImage
            };
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const startCamera = async () => {
        try {
            setCameraStatus('starting');
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            videoRef.current.srcObject = stream;
            videoRef.current.play();
            setCameraStatus('started');
        } catch (error) {
            console.error('Error al iniciar la cámara:', error);
        }
    };

    const stopCamera = async () => {
        try {
            if (videoRef.current.srcObject) {
                const stream = videoRef.current.srcObject;
                const tracks = stream.getTracks();
                tracks.forEach(track => track.stop());
                videoRef.current.srcObject = null;
                setCameraStatus('idle');
            }
        } catch (error) {
            console.error('Error al detener la camara:', error)
        }
    }

    const capturePhoto = () => {
        const context = canvasRef.current.getContext('2d');
        const video = videoRef.current;
        context.drawImage(video, 0, 0, canvasRef.current.width, canvasRef.current.height);

        const offScreenCanvas = document.createElement('canvas');
        offScreenCanvas.width = video.videoWidth;
        offScreenCanvas.height = video.videoHeight;
        const offScreenContext = offScreenCanvas.getContext('2d');
        offScreenContext.drawImage(video, 0, 0);

        const cropWidth = 400;
        const cropHeight = 400;
        const cropX = (offScreenCanvas.width - cropWidth) / 2;
        const cropY = (offScreenCanvas.height - cropHeight) / 2;

        const finalCanvas = document.createElement('canvas');
        finalCanvas.width = cropWidth;
        finalCanvas.height = cropHeight;
        const finalContext = finalCanvas.getContext('2d');
        finalContext.drawImage(offScreenCanvas, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

        const finalPhoto = finalCanvas.toDataURL('image/png');
        photoRef.current.src = finalPhoto
        setPhoto(finalPhoto);
        stopCamera()
    };

    return (
        <>
            {isIphoneDevice ? (
                <div className="fade-in">
                    <div className="mb-4">
                        <h2 className="block text-md mt-0 mb-1 font-semibold text-gray-700">Sacate una selfie</h2>
                        <div className="flex items-center relative h-[165px] bg-gray-200 rounded-lg">

                            <div className='absolute w-full top-3 text-center'>
                                <canvas ref={canvasRef} className="hidden" />
                                {photo &&
                                    <div className='border rounded-full overflow-hidden relative w-[140px] h-[140px] mx-auto'>
                                        <img
                                            src={photo}
                                            alt="captured"
                                            className="object-cover w-full h-full mx-auto"
                                        />

                                        <div className="absolute w-full transform translate-y-[50%]">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="76" height="76" fill="currentColor" className="bi bi-camera mx-auto" viewBox="0 0 16 16">
                                                <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4z" />
                                                <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5m0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7M3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0" />
                                            </svg>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            ) : (
                <div className="fade-in">
                    <div className="mb-4">
                        <h2 className="block text-md mt-0 mb-1 font-semibold text-gray-700">Sacate una selfie</h2>
                        <div className="flex items-center relative">
                            <video ref={videoRef} className="w-full bg-gray-200 rounded-md" />
                            {cameraStatus !== 'started' && (
                                <div className="absolute w-full">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="76" height="76" fill="currentColor" className="bi bi-camera mx-auto" viewBox="0 0 16 16">
                                        <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4z" />
                                        <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5m0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7M3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0" />
                                    </svg>
                                </div>
                            )}
                            <div className='absolute w-[90px] top-3 left-3'>
                                <canvas ref={canvasRef} className="hidden" />
                                {photo &&
                                    <div className='border rounded-full overflow-hidden relative w-[95px] h-[95px]'>
                                        <img
                                            src={photo}
                                            alt="captured"
                                            className="object-cover w-full h-full"
                                        />
                                    </div>
                                }
                            </div>
                        </div>

                        <div className='text-center mt-5 mb-4'>


                            {cameraStatus === 'idle' && (
                                <button type="button" onClick={startCamera} className="w-full mt-3 transition-all text-white font-bold py-3 px-6 focus:outline-none focus:shadow-outline bg-[#FF5EA3] rounded-lg hover:bg-[#FF7DF2] hover:scale-105">
                                    Iniciar Cámara
                                </button>
                            )}
                            {cameraStatus === 'starting' && <p className='mt-3 text-sm text-center text-gray-500 min-h-[49px]'>Iniciando Cámara...</p>}
                            {cameraStatus === 'started' && (
                                <>
                                    <button type="button" onClick={capturePhoto} className="w-full mt-2 transition-all text-white font-bold py-3 px-6 focus:outline-none focus:shadow-outline bg-[#FF5EA3] rounded-lg hover:bg-[#FF7DF2] hover:scale-105">
                                        Capturar Selfie
                                    </button>
                                    <button type="button" onClick={stopCamera} className="w-full mt-2 transition-all text-white font-bold py-3 px-6 focus:outline-none focus:shadow-outline bg-[#FF5EA3] rounded-lg hover:bg-[#FF7DF2] hover:scale-105">
                                        Detener Camara
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
            <div>
                <button
                    type="button"
                    onClick={() => document.getElementById('fileInput').click()}
                    className="w-full transition-all text-white font-bold py-3 px-6 focus:outline-none focus:shadow-outline bg-[#FF5EA3] rounded-lg hover:bg-[#FF7DF2] hover:scale-105"
                >
                    Subir Imagen
                </button>
                <input
                    id="fileInput"
                    type="file"
                    accept="image/*"
                    onChange={handleFileUpload}
                    className="hidden"
                />
            </div>
        </>
    )
}
export default (Photo);