import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

function AuthOverlay({ authSuccess }) {
    const [scale, setScale] = useState(0);
    const [circleSize, setCircleSize] = useState(0);
  
    useEffect(() => {
      if (authSuccess) {
        setScale(4);
        setCircleSize(Math.min(window.innerWidth, window.innerHeight));
      } else {
        setScale(0);
      }
    }, [authSuccess]);
  
    return ReactDOM.createPortal(
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: `translate(-50%, -50%) scale(${scale})`,
          width: circleSize,
          height: circleSize,
          borderRadius: '50%',
          backgroundColor: '#FF5EA3',
          transition: 'transform .4s ease-in-out',
          zIndex: 1000,
        }}
      ></div>,
      document.body
    );
  }
  
  export default AuthOverlay;
  
  
