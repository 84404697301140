import CopyLink from "./CopyLink";


export default function PaymentInstructions({ transferencias }) {
  const parseInstruction = (instruction) => {
    let amount, to, method, from, nombre, alias;

    if (instruction.startsWith('En Efectivo')) {
      method = 'Efectivo';
      const match = instruction.match(/se debe pagar (\d+) a ([^,]+)/);
      amount = match ? match[1] : '0';
      to = match ? match[2] : '';
      from = 'Se debe pagar en Efectivo';
    } else {
      method = 'Transferencia';
      const nombreMatch = instruction.match(/a\s([\w\s]+)\s,/); // Captura el nombre entre "a" y ","
      const cuenta = instruction.match(/alias\s=\s([\w.]+)/); // Captura el alias después de "alias ="
      const match = instruction.match(/([^,]+) debe pagar (\d+) a ([^,]+)/);
      from = match ? match[1] : '';
      amount = match ? match[2] : '0';
      to = match ? match[3] : '';
      nombre = nombreMatch ? nombreMatch[1] : ''
      alias = cuenta ? cuenta[1] : ''
      console.log(match)
    }

    return { from, to, amount, method, paid: false, nombre, alias };
  };

  const parsedInstructions = transferencias.map(parseInstruction);


  return (
    <div className="max-w-full sm:max-w-2xl mx-auto p-4 sm:p-6">
      {parsedInstructions.map((instruction, index) => (
        instruction.from !== '' && instruction.to !== '' && instruction.amount !== '0' && (
          <div key={index} className="flex items-center justify-between flex-wrap space-y-2 sm:space-y-0 p-4 bg-white border border-gray-200 rounded-xl w-full mb-4">
            {instruction.method === 'Efectivo' ? (
              <div className="flex-grow flex justify-between">
                <p className="text-sm sm:text-medium text-gray-600 mt-1 py-1">
                  <span className="font-bold">{instruction.from}</span> a{' '}
                  <span className="font-bold">{instruction.to}</span> los siguientes importes:
                </p>
                <div className={`block max-w-xs mx-auto px-4 py-2 mt-2 text-base font-medium rounded-full shadow ${instruction.method === 'Efectivo' ? 'bg-green-100 text-green-800' : 'bg-purple-100 text-purple-800'}`}>
                  {instruction.amount} {instruction.method === 'Efectivo' ? 'en Efectivo' : 'por Transferencia'}
                </div>


              </div>
            ) : (
              <div className="flex-grow flex justify-between">
                <p className="text-sm sm:text-medium text-gray-600 mt-1 py-1">
                  <span className="font-bold">{instruction.from}</span> debe enviarle a{' '}
                  <span className="font-bold">{instruction.to}
                    <CopyLink text={instruction.alias} />
                  </span> el siguiente importe:
                </p>
                <div className={`block max-w-xs mx-auto px-4 py-2 mt-2 text-base font-medium rounded-full shadow ${instruction.method === 'Efectivo' ? 'bg-green-100 text-green-800' : 'bg-purple-100 text-purple-800'}`}>
                  {instruction.amount} {instruction.method === 'Efectivo' ? 'en Efectivo' : 'por Transferencia'}
                </div>

              </div>
            )}
          </div>
        )
      ))}
    </div>
  );
}