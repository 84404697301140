import React, { useEffect, useState, useMemo } from 'react';

const Consejos = () => {
  const items = useMemo(() => [
    "Sonreí y sé amable con los clientes. Por ejemplo, deciles '¡Hola, campeón!' cuando los saludás.",
    "Presentate, deciles tu nombre y preguntales el suyo. 90% de las veces, eso solo significa una gran propina.",
    "Mantené una actitud positiva y servicial. Y no, no vale decir 'sí, ya va' con cara de 'me quiero morir' Ponele onda!",
    "Recomendá platos y bebidas de manera entusiasta. 'Mirá, este postre te cambia la vida, te lo juro'.",
    "Verificá regularmente si los clientes necesitan algo. Tipo '¿todo bien acá o les traigo un mojito?' cada tanto.",
    "Estate atento, si un cliente comienza a toser, acercale un vaso de agua. Un vaso de agua te puede significar alta propina.",
    "Sé atento a los detalles y personalizá el servicio. 'Ya sé que a vos te gusta el café sin azúcar, ¿verdad?'",
    "Agradecé a los clientes por su visita. 'Gracias por venir, los queremos más que a la cerveza fría'.",
    "Mostrá conocimiento sobre el menú y las promociones. 'Este plato es como Messi, siempre rinde'.",
    "Despedite con una sonrisa y un agradecimiento. 'Chau, vuelvan pronto o esto se convierte en un Starbucks!'."
  ], []);

  const [currentItem, setCurrentItem] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * items.length);
    setCurrentItem(items[randomIndex]);
  }, [items]);

  const handleClick = () => {
    const randomIndex = Math.floor(Math.random() * items.length);
    setCurrentItem(items[randomIndex]);
    setButtonClicked(true);

    // Resetear el botón después de un breve retraso para animar
    setTimeout(() => {
      setButtonClicked(false);
    }, 300); // Ajusta el tiempo según lo deseado para que la animación sea visible
  };

  return (
    <div className="max-w-md mx-auto relative">
    <div className="flex items-center justify-end">
      <div className="relative">
      <div className="bg-blue-200 p-4 my-6 rounded-lg h-[130px] flex items-center justify-center">
        {currentItem}
      </div>

        
        <div className="absolute bottom-6 transform translate-x-[140px]">
          <div className="w-6 h-6 bg-blue-200 rotate-45 transform origin-bottom-left"></div>
        </div>
      </div>
    </div>


        <div className={`relative bottom-0 mt-2 transform ${buttonClicked ? 'translate-y-[-10px]' : 'translate-y-0'} transition-transform duration-240 ease-in-out`}>
          <img
            src="Tippit-logo-cuadrado-trasparente-300x300.png"
            className="img-fluid max-w-[55px] border-2 border-pink-300 p-1 rounded-full mx-auto mb-3"
            alt=""
          />
        </div>
       <button onClick={handleClick} className='transition:all 
                        duration-300 
                        ease-in-out 
                        w-full 
                        px-2 
                        py-4 
                        text-white 
                        font-semibold 
                        bg-[#FF5EA3] 
                        rounded-lg 
                        hover:bg-[#FF7DF2] 
                        hover:scale-105 
                        mt-5'>¡Dame otro consejo!</button>
    </div>
  );
};


export default Consejos;
