import { useCallback, useState } from "react"
import {fetchWeeklyData, fetchRoles} from "../utils/restaurantDataGetter"

//TODO: completar este customHook
export function useRestaurant({ token }){
    const [mesaData, setMesaData] = useState(null)
    const [sessionExpired, setSessionExpired] = useState(false)
    const [roles, setRoles] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const getWeeklyData = useCallback(async () => {
        try{
            const newMesas = await fetchWeeklyData(token);

            setMesaData(newMesas)
        } catch (e){
            if (e.message.includes("401")){
                setSessionExpired(true)
            }
        }
    },[token])

    const getRolesData = useCallback(async () => {
        try{
            setIsLoading(true)
            const newRoles = await fetchRoles(token);
            console.log(newRoles)
            setRoles(newRoles)
        } catch (e){
            if (e.message.includes("401")){
                setSessionExpired(true)
            }
        } finally {
            setIsLoading(false)
        }
    }, [token])
    return {mesaData, getWeeklyData, sessionExpired, getRolesData, roles, isLoading}
}