import React, { useState, useRef, useEffect } from 'react';
import { Lock } from "react-bootstrap-icons";
import {
  Eye,
  EyeFill
} from "react-bootstrap-icons";

const isIphone = () => /iPhone/.test(navigator.userAgent);

const Registrarse = () => {
  const [email, setEmail] = useState('');
  const [nombre, setNombre] = useState('');
  const [password, setPassword] = useState('');
  const [rol, setRol] = useState('');
  const [alias, setAlias] = useState('');
  const [address, setAddress] = useState('');
  const [cuit, setCuit] = useState('');
  const [photo, setPhoto] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [step, setStep] = useState(1);
  const [message, setMessage] = useState('');
  const [cameraStatus, setCameraStatus] = useState('idle');
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [cuitError, setCuitError] = useState('');
  const [phoneError, setPhoneError] = useState(false)
  const [cuitOk, setCuitOk] = useState(false);
  const [phoneOk, setPhoneOk] = useState(false);


  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  //   Detectamos si es iPhone
  const [isIphoneDevice, setIsIphoneDevice] = useState(false);

  useEffect(() => {
    setIsIphoneDevice(isIphone());
  }, []);

  const [isStep1Valid, setIsStep1Valid] = useState(false);
  const [isStep2Valid, setIsStep2Valid] = useState(false);
  const [isStep3Valid, setIsStep3Valid] = useState(false);
  const [isStep4Valid, setIsStep4Valid] = useState(false);

  const [isValid, setIsValid] = useState(true);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateTelefonoArgentino = (numero) => {
    const regex = /^549\d{2,4}\d{6,8}$/
    return regex.test(numero);
  }

  const checkPhoneNumber = (numero) => {
    numero = '549' + numero;
    if (validateTelefonoArgentino(numero)) {
      setPhoneError(false)
      setPhoneOk(true)
    }
    else {
      setPhoneError(true)
      setPhoneOk(false)
    }
  }

  const handleChange = (event) => {
    const emailValue = event.target.value;
    setEmail(emailValue);
    setIsValid(validateEmail(emailValue));
  };






  const buildRequestBody = (data) => {
    const queryString = Object.keys(data)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join('&');
    return queryString;
  };

  const checkEmail = async (email) => {
    const data = {
      nombre: '  ', // Espacio doble para asegurarse que no esté vacío
      rol: 'Mozo',
      photo: '  ',
      username: email, // Espacio doble para asegurarse que no esté vacío
      password: '  ', // Espacio doble para asegurarse que no esté vacío
      email: email,
      cuit: '  ' // Espacio doble para asegurarse que no esté vacío
    };

    const requestBody = buildRequestBody(data);
    //console.log('Request Body:', requestBody);

    try {
      const response = await fetch('https://tippitqr.com/userdb/register?disable=false&vinculado_mp=false', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'accept': 'application/json'
        },
        body: requestBody
      });

      console.log('Response Status:', response.status);
      const responseData = await response.json();
      console.log('Response Data:', responseData);

      if (!response.ok) {
        if (response.status === 404) {
          setEmailError('El email ya existe');
          setIsStep1Valid(false);
        } else {
          setEmailError(' ');
        }
      } else {
        setEmailError('');
        setIsStep1Valid(true);
        setStep(2)
      }
    } catch (error) {
      console.error('Error verificando el email:', error);
    }
  };

  const checkCuit = async (cuit) => {
    if (cuit.length < 1) {
      setCuitError('El CUIT no puede estar vacío');
      setCuitOk(false);
      return
    }
    const data = {
      nombre: '  ', // Espacio doble para asegurarse que no esté vacío
      rol: 'Mozo',
      photo: ' ',
      username: '  ', // Espacio doble para asegurarse que no esté vacío
      password: '  ', // Espacio doble para asegurarse que no esté vacío
      email: ' ', // Espacio doble para asegurarse que no esté vacío
      cuit: cuit // El CUIT a verificar
    };

    const requestBody = buildRequestBody(data);
    console.log('Request Body:', requestBody);

    try {
      const response = await fetch('https://tippitqr.com/userdb/register?disable=false&vinculado_mp=false', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'accept': 'application/json'
        },
        body: requestBody
      });

      console.log('Response Status:', response.status);
      const responseData = await response.json();
      console.log('Response Data:', responseData);

      if (!response.ok) {
        if (response.status === 404) {
          setCuitError('El CUIT ya existe');
          setCuitOk(false);
        } else if (response.status === 422) {
          setCuitError('El CUIT no es valido');
          setCuitOk(false);
        } else {
          setCuitError(' ');
          setCuitOk(true);
        }
      } else {
        setCuitError(' ');
        setCuitOk(true);
      }
    } catch (error) {
      console.error('Error verificando el CUIT:', error);
    }
  };









  useEffect(() => {
    setIsStep1Valid(nombre !== '' && email !== '' && password !== '');
  }, [nombre, email, password]);

  useEffect(() => {
    setIsStep2Valid(rol !== '');
  }, [rol]);

  useEffect(() => {
    setIsStep3Valid(cuitOk && alias.length >= 1 && nombre.length >= 1 && phoneOk);
  }, [cuitOk, alias, nombre, phoneOk]);

  useEffect(() => {
    setIsStep4Valid(photo !== '');
  }, [photo]);

  useEffect(() => {
    const videoElement = videoRef.current;
    return () => {
      if (videoElement && videoElement.srcObject) {
        const stream = videoElement.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach(track => track.stop());
        videoElement.srcObject = null;
      }
    };
  }, []);

  const handlePhotoStepBack = () => {
    if (videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());
      videoRef.current.srcObject = null;
      setCameraStatus('idle');
    }
    setStep(step - 1);
  };

  const handleStepBack = () => {
    setStep(step - 1);
    setCuitError('')
    setPhoneError(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new URLSearchParams();
    formData.append("id", "");
    formData.append("nombre", nombre);
    formData.append("username", email);
    formData.append("cuit", rol === "Restaurante" ? "  " : cuit);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("rol", rol);
    formData.append("photo", photo);
    //formData.append("phone", rol === "Restaurante" ? "  " : phoneNumber)
    try {
      const response = await fetch(`https://tippitqr.com/userdb/register?address=${address}&alias=${alias}&phone=${phoneNumber}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData.toString(),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error en el registro');
      }
      setMessage('LISTO YA TE REGISTRASTE! Te tiene que estar llegando un email para activar tu cuenta (no te olvides de fijarte en la carpeta Spam!)');
      setTimeout(() => {
        window.location.reload();
      }, 10000);

    } catch (error) {
      setMessage(`Error al registrar el usuario: ${error.message}`);
      console.error('Error:', error);
      setTimeout(() => {
        setMessage('');
      }, 5000);
    } finally {
      setIsSubmitting(false);
    }
  };






  //   SUBIR FOTO
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        // Crear un canvas para el redimensionamiento
        const resizeCanvas = document.createElement('canvas');
        const resizeCtx = resizeCanvas.getContext('2d');

        const targetWidth = 500;
        const scaleFactor = targetWidth / img.width;
        const targetHeight = img.height * scaleFactor;

        resizeCanvas.width = targetWidth;
        resizeCanvas.height = targetHeight;

        resizeCtx.drawImage(img, 0, 0, targetWidth, targetHeight);

        const cropCanvas = document.createElement('canvas');
        const cropCtx = cropCanvas.getContext('2d');

        const cropSize = 400;
        cropCanvas.width = cropSize;
        cropCanvas.height = cropSize;

        const sx = (targetWidth - cropSize) / 2;
        const sy = (targetHeight - cropSize) / 4;

        cropCtx.drawImage(resizeCanvas, sx, sy, cropSize, cropSize, 0, 0, cropSize, cropSize);

        const croppedImage = cropCanvas.toDataURL();
        setPhoto(croppedImage);
      };
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };




  const startCamera = async () => {
    try {
      setCameraStatus('starting');
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
      videoRef.current.play();
      setCameraStatus('started');
    } catch (error) {
      console.error('Error al iniciar la cámara:', error);
    }
  };

  const capturePhoto = () => {
    const context = canvasRef.current.getContext('2d');
    const video = videoRef.current;
    context.drawImage(video, 0, 0, canvasRef.current.width, canvasRef.current.height);

    const offScreenCanvas = document.createElement('canvas');
    offScreenCanvas.width = video.videoWidth;
    offScreenCanvas.height = video.videoHeight;
    const offScreenContext = offScreenCanvas.getContext('2d');
    offScreenContext.drawImage(video, 0, 0);

    const cropWidth = 400;
    const cropHeight = 400;
    const cropX = (offScreenCanvas.width - cropWidth) / 2;
    const cropY = (offScreenCanvas.height - cropHeight) / 2;

    const finalCanvas = document.createElement('canvas');
    finalCanvas.width = cropWidth;
    finalCanvas.height = cropHeight;
    const finalContext = finalCanvas.getContext('2d');
    finalContext.drawImage(offScreenCanvas, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

    const finalPhoto = finalCanvas.toDataURL('image/png');
    setPhoto(finalPhoto);
  };

  return (
    <div className="max-w-md mx-auto">
      {message && <div className="bg-green-500 text-white p-3 px-4 rounded message fadeMsgIn">{message}</div>}
      <form onSubmit={handleSubmit} className="mb-4 text-lg">
        {step === 1 && (
          <div className="fade-in">
            <h2 className="block text-md mt-0 mb-1 font-semibold text-gray-700">Registrarme en Tippit</h2>
            <div className="mb-4 mt-3 relative">
              {!isValid && <p className='text-red-500 text-xs absolute right-0 mt-3 mr-2'>Email inválido</p>}
              <input
                type="email"
                id="email"
                placeholder='Email'
                name="email"
                value={email}
                onChange={handleChange}

                className={`mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm ${isValid ? 'border-gray-300 focus:ring-pink-500 focus:border-pink-500' : 'border-red-500 focus:ring-red-500 focus:border-red-500'
                  }`}
                tabIndex="2"
                required
              />
              {emailError && <p className='text-sm text-red-500 text-center'>{emailError}</p>}
            </div>
            <div className="mb-4 relative">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder="Clave"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className="mt-1 block w-full pl-9 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                tabIndex="3"
                required
                autoComplete="password"
              />


              <div
                onClick={() => setShowPassword(!showPassword)}
                className="cursor-pointer absolute right-4 top-3"
              >
                {showPassword ? <EyeFill width={20} height={20} /> : <Eye width={20} height={20} />}
              </div>


              <Lock className={`absolute left-3 top-[10px] transform h-5 w-5 ${isFocused ? 'text-[#FF5EA3]' : 'text-gray-300'
                } transition-colors duration-300`} />
            </div>
            {password.length < 8 && password.length !== 0 && <p className='text-sm text-red-500 text-center'>La contraseña tiene que tener minimo 8 caracteres</p>}
            <button type="button" onClick={() => checkEmail(email)} disabled={!(email.length > 0 & password.length >= 8)} tabIndex="4"
              className={`w-full transition-all text-white font-bold py-4 px-6 focus:outline-none focus:shadow-outline rounded-lg text-base ${email.length > 0 & password.length >= 8
                ? 'bg-[#FF5EA3] hover:bg-[#FF7DF2] hover:scale-105'
                : 'bg-gray-400 cursor-not-allowed'
                }`}>
              Siguiente
            </button>
          </div>
        )}
        {step === 2 && (
          <div className="fade-in">
            <div className="mb-4">
              <h2 className="block text-md mt-0 mb-1 font-semibold text-gray-700">Como queres registrarte?</h2>
              <div className="mt-3 space-x-4 flex">
                <label className={`inline-flex items-center relative border-2 w-1/2 shadow-md ${rol === 'Mozo' ? 'border-pink-300 text-pink-500 bg-pink-50' : ''} rounded-md cursor-pointer py-5`}>
                  <input type="radio" name="rol" value="Mozo" onChange={(e) => setRol(e.target.value)} className="form-radio invisible" tabIndex="5" required />
                  {rol === 'Mozo' && (<div className='absolute right-2 bottom-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                  </div>)}
                  <span className="ml-2">Personal</span>
                </label>
                <label className={`inline-flex items-center relative border-2 w-1/2 shadow-md ${rol === 'Restaurante' ? 'border-pink-300 text-pink-500 bg-pink-50' : ''} rounded-md cursor-pointer py-5`}>
                  <input type="radio" name="rol" value="Restaurante" onChange={(e) => setRol(e.target.value)} className="form-radio invisible" tabIndex="6" required />
                  {rol === 'Restaurante' && (<div className='absolute right-2 bottom-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                  </div>)}
                  <span className="ml-2">Restaurante</span>
                </label>
              </div>
            </div>
            <div className="flex justify-between mt-5">
              <button type="button" onClick={() => setStep(1)} className="transition-all text-base text-white font-bold py-4 px-6 focus:outline-none focus:shadow-outline bg-gray-500 rounded-lg hover:bg-[#FF7DF2] hover:scale-105">
                Atrás
              </button>
              <button type="button" onClick={() => setStep(3)} disabled={!isStep2Valid} tabIndex="7"
                className={`transition-all text-white font-bold py-4 px-6 focus:outline-none focus:shadow-outline rounded-lg w-auto text-base ${isStep2Valid
                  ? 'bg-[#FF5EA3] hover:bg-[#FF7DF2] hover:scale-105'
                  : 'bg-gray-400 cursor-not-allowed'
                  }`}>
                Siguiente
              </button>
            </div>
          </div>
        )}
        {step === 3 && (
          <div className="fade-in">
            {rol === "Mozo" ? (
              <div className="mb-4 mt-3">
                <input
                  type="nombre"
                  id="nombre"
                  placeholder="Nombre y Apellido"
                  name="nombre"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                  tabIndex="1"
                  required
                />
                <input
                  type="alias"
                  id="alias"
                  placeholder="Ingrese un alias de alguna billetera virtual"
                  name="alias"
                  value={alias}
                  onChange={(e) => setAlias(e.target.value)}
                  className="mt-3 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                  tabIndex="1"
                  required
                />
                <div className="mb-4 mt-3">
                  <input
                    type="cuit"
                    id="cuit"
                    placeholder='CUIT'
                    name="cuit"
                    value={cuit}
                    onChange={(e) => {
                      const value = e.target.value;
                      const onlyNums = value.replace(/[^0-9]/g, '');
                      setCuit(onlyNums);
                    }}
                    onBlur={() => checkCuit(cuit)}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                    tabIndex="8"
                    required
                    maxLength={11}
                  />
                  {cuitError && <p className='text-sm text-red-500 text-center'>{cuitError}</p>}
                </div>
                <div>
                  <div class="flex items-center">
                    <span class="mt-1 inline-flex items-center px-3 py-2 text-sm text-gray-500 bg-gray-100 border border-r-0 border-gray-300 rounded-l-md whitespace-nowrap h-full">
                      +54 9
                    </span>
                    <input
                      type='text'
                      id='phoneNumber'
                      placeholder='Numero de telefono'
                      name='phoneNumber'
                      value={phoneNumber}
                      onChange={(e) => {
                        const value = e.target.value
                        const onlyNums = value.replace(/[^0-9]/g, '');
                        setPhoneNumber(onlyNums);
                      }}
                      className="mt-1 block w-full px-3 py-2 border border-l-0 border-gray-300 rounded-r-md shadow-sm focus:outline-none focus:ring-pink-500 focus:border-pink-500 text-sm"
                      required
                      maxLength={14}
                      onBlur={() => checkPhoneNumber(phoneNumber)}
                    />
                  </div>
                  {phoneError && <p className='text-sm text-red-500 text-center'>Número ingresado inválido</p>}
                  {!phoneError && (
                    <div className='space-y-2 mt-1'>
                      <p className='text-xs text-gray-400'>Ingrese:</p>
                      <p className='text-xs text-gray-400'>-Código de área (ej: 221)</p>
                      <p className='text-xs text-gray-400'>-Número de teléfono</p>
                    </div>
                  )}
                </div>
              </div>

            ) : (
              <div className="mb-4 mt-3">
                <input
                  type="nombre"
                  id="nombre"
                  placeholder="Nombre del restaurante"
                  name="nombre"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                  tabIndex="1"
                  required
                />
                <input
                  type="address"
                  id="address"
                  placeholder="Direccion del Restaurante"
                  name="address"
                  value={address}
                  onChange={(e) => {
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^a-zA-Z0-9\s]/g, "");
                    setAddress(filteredValue);
                  }}
                  className="mt-4 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                  tabIndex="1"
                  required
                />
              </div>)}

            <div className="flex justify-between">
              <button type="button" onClick={handleStepBack} tabIndex="9"
                className="w-[47%] bg-gray-400 hover:bg-gray-500 transition-all text-white font-bold py-4 px-6 rounded-lg focus:outline-none focus:shadow-outline text-base">
                Atrás
              </button>
              {rol === "Mozo" ? (
                <button type="button" onClick={() => setStep(4)} disabled={!isStep3Valid} tabIndex="10"
                  className={`w-[47%] transition-all text-white font-bold py-4 px-6 focus:outline-none focus:shadow-outline rounded-lg text-base ${isStep3Valid
                    ? 'bg-[#FF5EA3] hover:bg-[#FF7DF2] hover:scale-105'
                    : 'bg-gray-400 cursor-not-allowed'
                    }`}>
                  Siguiente
                </button>
              ) : (
                <button type="button" onClick={() => setStep(4)} disabled={!(nombre.length > 0 & address.length > 0)} tabIndex="10"
                  className={`w-[47%] transition-all text-white font-bold py-4 px-6 focus:outline-none focus:shadow-outline rounded-lg text-base ${nombre.length > 0 & address.length > 0
                    ? 'bg-[#FF5EA3] hover:bg-[#FF7DF2] hover:scale-105'
                    : 'bg-gray-400 cursor-not-allowed'
                    }`}>
                  Siguiente
                </button>
              )}
            </div>
          </div>
        )}

        {!isIphoneDevice && step === 4 && (
          <div className="fade-in">
            <div className="mb-4">
              <h2 className="block text-md mt-0 mb-1 font-semibold text-gray-700">Sacate una selfie</h2>
              <div className="flex items-center relative">
                <video ref={videoRef} className="w-full bg-gray-200 rounded-md" />
                {cameraStatus !== 'started' && (
                  <div className="absolute w-full">
                    <svg xmlns="http://www.w3.org/2000/svg" width="76" height="76" fill="currentColor" className="bi bi-camera mx-auto" viewBox="0 0 16 16">
                      <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4z" />
                      <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5m0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7M3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0" />
                    </svg>
                  </div>
                )}
                <div className='absolute w-[90px] top-3 left-3'>
                  <canvas ref={canvasRef} className="hidden" />
                  {photo &&


                    <div className='border rounded-full overflow-hidden relative w-[95px] h-[95px]'>
                      <img
                        id='miImagen'
                        src={photo}
                        alt="captured"
                        className="object-cover w-full h-full"
                      />
                    </div>
                  }
                </div>
              </div>

              <div className='text-center mt-5 mb-4'>

                <div>
                  <button
                    type="button"
                    onClick={() => document.getElementById('fileInput').click()}
                    className="w-full transition-all text-white font-bold py-3 px-6 focus:outline-none focus:shadow-outline bg-[#FF5EA3] rounded-lg hover:bg-[#FF7DF2] hover:scale-105"
                  >
                    Subir Imagen
                  </button>
                  <input
                    id="fileInput"
                    type="file"
                    accept="image/*"
                    onChange={handleFileUpload}
                    className="hidden"
                  />
                </div>
                {cameraStatus === 'idle' && (
                  <button type="button" onClick={startCamera} className="w-full mt-3 transition-all text-white font-bold py-3 px-6 focus:outline-none focus:shadow-outline bg-[#FF5EA3] rounded-lg hover:bg-[#FF7DF2] hover:scale-105">
                    Iniciar Cámara
                  </button>
                )}
                {cameraStatus === 'starting' && <p className='mt-3 text-sm text-center text-gray-500 min-h-[49px]'>Iniciando Cámara...</p>}
                {cameraStatus === 'started' && (
                  <button type="button" onClick={capturePhoto} className="w-full mt-2 transition-all text-white font-bold py-3 px-6 focus:outline-none focus:shadow-outline bg-[#FF5EA3] rounded-lg hover:bg-[#FF7DF2] hover:scale-105">
                    Capturar Selfie
                  </button>
                )}
              </div>




            </div>
            <div className="flex justify-between">
              <button type="button" onClick={handlePhotoStepBack} disabled={!isStep3Valid} className="transition-all text-white font-bold py-3 px-6 focus:outline-none focus:shadow-outline bg-gray-500 rounded-lg hover:bg-[#FF7DF2] hover:scale-105 text-base">
                Atrás
              </button>
              <button
                type="submit"
                disabled={!isStep4Valid || isSubmitting}
                className={`transition-all text-white font-bold py-4 px-6 focus:outline-none focus:shadow-outline rounded-lg text-base ${!isStep4Valid || isSubmitting
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-[#FF5EA3] hover:bg-[#FF7DF2] hover:scale-105"
                  }`}
              >
                Registrarme
              </button>


            </div>
          </div>
        )}

        {isIphoneDevice && step === 4 && (
          <div className="fade-in">
            <div className="mb-4">
              <h2 className="block text-md mt-0 mb-1 font-semibold text-gray-700">Sacate una selfie</h2>
              <div className="flex items-center relative h-[165px] bg-gray-200 rounded-lg">

                <div className='absolute w-full top-3 text-center'>
                  <canvas ref={canvasRef} className="hidden" />
                  {photo &&


                    <div className='border rounded-full overflow-hidden relative w-[140px] h-[140px] mx-auto'>
                      <img
                        src={photo}
                        alt="captured"
                        className="object-cover w-full h-full mx-auto"
                      />

                      <div className="absolute w-full transform translate-y-[50%]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="76" height="76" fill="currentColor" className="bi bi-camera mx-auto" viewBox="0 0 16 16">
                          <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4z" />
                          <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5m0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7M3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0" />
                        </svg>
                      </div>
                    </div>
                  }
                </div>
              </div>

              <div className='text-center mt-5 mb-4'>

                <button
                  type="button"
                  onClick={() => document.getElementById('fileInput').click()}
                  className="w-full transition-all text-white font-bold py-3 px-6 focus:outline-none focus:shadow-outline bg-[#FF5EA3] rounded-lg hover:bg-[#FF7DF2] hover:scale-105"
                >
                  Subir Imagen
                </button>
                <input
                  id="fileInput"
                  type="file"
                  accept="image/*"
                  onChange={handleFileUpload}
                  className="hidden"
                />

              </div>




            </div>
            <div className="flex justify-between">
              <button type="button" onClick={handleStepBack} disabled={!isStep3Valid} className="transition-all text-white font-bold py-3 px-6 focus:outline-none focus:shadow-outline bg-gray-500 rounded-lg hover:bg-[#FF7DF2] hover:scale-105 text-base">
                Atrás
              </button>
              <button
                type="submit"
                disabled={!isStep4Valid || isSubmitting}
                className={`transition-all text-white font-bold py-4 px-6 focus:outline-none focus:shadow-outline rounded-lg text-base ${!isStep4Valid || isSubmitting
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-[#FF5EA3] hover:bg-[#FF7DF2] hover:scale-105"
                  }`}
              >
                Registrarme
              </button>


            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default Registrarse;