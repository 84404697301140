import React, { useState } from "react";
import { QuestionCircleFill } from "react-bootstrap-icons";

const ToolTip = ({ position = "top", text, width = "w-auto" }) => {
  const [isHovered, setIsHovered] = useState(false);

  const getTooltipPosition = () => {
    switch (position) {
      case "left":
        return { left: "0", transform: "translateX(-100%)", marginLeft: "-4px" };
      case "right":
        return { right: "0", transform: "translateX(100%)", marginRight: "-10px" };
      case "top":
        return { top: "0", left: "50%", transform: "translate(-50%, -100%)", marginTop: "-11px" };
      case "bottom":
        return { bottom: "0", left: "50%", transform: "translate(-50%, 100%)", marginBottom: "-10px" };
      default:
        return { top: "0", left: "50%", transform: "translate(-50%, -100%)", marginTop: "12px" };
    }
  };

  return (
    <div
      className="relative flex items-center"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Icono de información */}
      <QuestionCircleFill className="ml-1.5 cursor-pointer" width={18} height={18} />

      {/* Tooltip que aparece en hover */}
      {isHovered && text.length > 0 && (
        <div
          className={`absolute bg-pink-200 text-black text-xs rounded p-2 shadow-lg z-20 ${width}`}
          style={{
            ...getTooltipPosition(),
            whiteSpace: 'pre-line',
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default ToolTip;