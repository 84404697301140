// src/components/SessionOut.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';

const SessionOut = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/');
  };

  return (
<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-90 z-50">
      <div className="bg-white pt-6 rounded-lg shadow-lg text-center w-6/12 max-w-96 min-w-[355px] p-4">
        <h2 className="text-3xl font-bold">Sesión Expirada</h2>
        <h3 className="text-xl font-bold mb-3">No sos vos, somos nosotros 😢</h3>
        <p className='mb-4 text-md'>De verdad! No hiciste nada malo, la sesión expira solamente para mantener la seguridad de Tippit.</p>
        <p className='my-4'>Por favor, volvé  a ingresar.</p>
        <button
          onClick={handleButtonClick}
          className="transition:all 
          duration-300 
          ease-in-out 
          w-full 
          px-2 
          py-4 
        text-white 
          font-semibold 
        bg-[#FF5EA3] 
          rounded-lg 
        hover:bg-[#FF7DF2] 
          hover:scale-105 p-3"
        >
          Volver a ingresar
        </button>
      </div>
    </div>
  );
};

export default SessionOut;
