import { useEffect, useState, useCallback } from "react";
export default function Ranking({ tipo, range }) {
    const [top, setTop] = useState([])
    const currentMonth = new Date().getMonth() + 1;

    function crearRango(start, end) {
        return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    }
    const getYearlyData = useCallback(async () => {
        const token = sessionStorage.getItem('token')
        if (!token) {
            return [{}]
        }
        try {
            const response = await fetch(`https://tippitqr.com/api/propinas/año-actual?año_actual=2024`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error('Error al obtener los datos de las propinas');
            }
            else {
                const data = await response.json()
                //console.log(data)
                return (data.propinas_año_actual)
            }
        }
        catch (error) {
            console.error('Error al obtener los datos de las propinas:', error);
        }

    }, [])

    const rendimientoData = async (dateRange) => {
        const initialData = await getYearlyData();
        if (initialData === undefined) {
            return
        }
        //acumulamos los valores repetidos
        const filteredData = initialData.reduce((acumulador, item) => {
            const mozoId = item.propina.Mozo.id;

            if (dateRange.includes(item.mes)) {
                //Si el mozo ya existe en el acumulador, sumo los montos
                if (acumulador[mozoId]) {
                    if (item.propina.calificacion !== 0) {
                        acumulador[mozoId].calificacionesSum += item.propina.calificacion;
                        acumulador[mozoId].calificacionesCount += 1;
                    }
                }
                //sino, seteamos el acumulador para el nuevo mozo
                else {
                    if (item.propina.calificacion !== 0) {
                        acumulador[mozoId] = {
                            ...item,
                            calificacionesSum: item.propina.calificacion,
                            calificacionesCount: 1
                        };
                    }
                }
            }
            return acumulador
        }, [])

        //calculo el promedio y lo ordeno de mayor a menor
        const resultado = Object.values(filteredData).map(item => {
            const promedioCalificacion = item.calificacionesSum / item.calificacionesCount;
            return {
                ...item,
                promedioCalificacion: promedioCalificacion.toFixed(2),
                propina: {
                    ...item.propina,
                }
            };
        }).sort((a, b) => {
            return b.promedioCalificacion - a.promedioCalificacion;
        });
        console.log(resultado)
        setTop(resultado);
    }
    const bestPropinaData = async (dateRange) => {
        const initialData = await getYearlyData();
        if (initialData === undefined) {
            return
        }
        //acumulamos los valores repetidos
        const filteredData = initialData.reduce((acumulador, item) => {
            const mozoId = item.propina.Mozo.id;
            if (dateRange.includes(item.mes)) {
                //Si el mozo ya existe en el acumulador, sumo los montos
                if (acumulador[mozoId]) {
                    acumulador[mozoId].propinaSum += item.propina.monto;
                }
                //sino, seteamos el acumulador para el nuevo mozo
                else {
                    acumulador[mozoId] = {
                        ...item,
                        propinaSum: item.propina.monto,
                    };
                }
            }
            return acumulador
        }, []);
        const resultado = Object.values(filteredData).map(item => {
            return {
                propinaSum: item.propinaSum.toFixed(2),
                propina: {
                    ...item.propina,
                }
            };
        }).sort((a, b) => {
            return b.propinaSum - a.propinaSum;
        });
        setTop(resultado);
    }

    useEffect(() => {
        let dateRange;
        switch (range) {
            case "Últimos 30 días":
                dateRange = crearRango(currentMonth, currentMonth);
                break;
            case "Últimos 3 meses":
                dateRange = crearRango(currentMonth - 2, currentMonth);
                break;
            case "Últimos 6 meses":
                dateRange = crearRango(currentMonth - 5, currentMonth);
                break;
            case "Último año":
                dateRange = crearRango(1, 12);
                break;
            default:
                break;
        }

        if (tipo === 'rendimiento') {
            rendimientoData(dateRange);
        }
        else {
            bestPropinaData(dateRange);
        }
    }, [range])
    return (

        <div className="space-y-2">
            {top.map((item, index) => (

                <div id={item.propina.Mozo.nombre} className="flex items-center justify-between p-2 border rounded-md hover:text-black">
                    <div className="flex items-center space-x-2">
                        <span className="text-pink-500 bg-transparent text-2xl font-bold">{index + 1}</span>
                        <span>{item.propina.Mozo.nombre}</span>
                    </div>
                    {tipo === "rendimiento" ? (
                        <div className="flex items-center space-x-1">
                            <span>{item.promedioCalificacion}</span>
                            <StarIcon className="text-pink-500 fill-pink-500" />
                        </div>
                    ) : (
                        <div className="flex items-center space-x-1">
                            <span className="text-grey-200 text-lg">${item.propinaSum}</span>
                        </div>
                    )}
                </div>


            )).slice(0, 5)}
        </div>
    )
}

function StarIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
        </svg>
    )
}