import toast, { Toaster } from 'react-hot-toast';
const CopyLink = ({ text }) => {
    const handleCopy = (event) => {
        event.preventDefault();
        navigator.clipboard.writeText(text)
            .then(() => {
                toast('Copiado al portapapeles', {
                    icon: '📋',
                    duration: 3000,
                    position: "top-center",
                    style: {
                        background: "rgb(255, 255, 255)",
                        color: "rgb(0, 0, 0)",
                    },
                });
            })
            .catch(err => {
                console.error('Error al copiar: ', err);
            });
    };

    return (
        <>
            <Toaster 
            />
            <a
                href={text}
                onClick={handleCopy}
                className="text-blue-500 underline cursor-pointer hover:text-blue-600"
            >
                {text}
            </a>
        </>
    );
};

export default CopyLink;